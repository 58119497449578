import React from "react"

import Layout from "../components/layout"

import Hero from "../components/hero"
// import Features from "../components/features"

import pricing from "../components/pricing"
import Menu from "../components/menu"
import frozendimsum from "../components/frozendimsum"
// import CalltoAction from "../components/call-to-action"
import Screenshots from "../components/screenshots"
import Testimonials from "../components/testimonials"
import Products from "../components/products"
// import Subscribe from "../components/subscribe"

import { useSiteMetadata } from "../hooks/use-site-metadata"

export default () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    "hero": Hero,
    // "features": Features,
    "frozendimsum": frozendimsum,
    "pricing": pricing,
    "menu": Menu,
    // "call-to-action": CalltoAction,
    "screenshots": Screenshots,
    "testimonials": Testimonials,
    "products": Products,
  }

  return(
    <>
      <Layout>
        { sections.map(section => {
          let Tagname = availableSections[section]
          return <Tagname />
        }) }
      </Layout>
    </>
  )
}
