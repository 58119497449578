import React from "react"
// import { Link } from "gatsby"
import content from "../../content/frozendimsum.yaml"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'

export default () => {

  // library.add(fas)

  return (
    <section id="frozendimsum">
      <div className="row section-head">
        <h1>{ content.title }</h1>
        <p>{ content.body }</p>
        <a className="button" href="https://goodchoice.xdineapp.com">Order Frozen Dim Sum</a>
        <p></p>
        <a className="button" href="/products">Dim Sum Cooking Instructions</a>
      </div>

    </section>
  )
}
