import React from "react";
// import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import content from '../../content/menu.yaml'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus } from '@fortawesome/free-solid-svg-icons'

// const menu = content.menu

// export default class LightboxExample extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       photoIndex: 0,
//       isOpen: false,
//     };
//   }

  // render() {
  //   const { photoIndex, isOpen } = this.state;
export default () => {
    return (
      <section id="menu">

        <div className="row section-head">
          <h1>{ content.title }</h1>
          <p>{ content.body }</p>

          <a className="button" href="/images/menu/dimsummenu.pdf">Dim Sum Menu</a>
          <p></p>

          <a className="button" href="/images/menu/dscombo.pdf">Dim Sum Combo</a>
          <p></p>

          <a className="button" href="/images/menu/takeoutflyer.pdf">Dinner Menu</a>
          <p></p>

          <a className="button" href="/images/menu/combo.pdf">Dinner Combo</a>
          <p></p>
          <a className="button" href="/images/menu/menup.pdf">Special Set Menu</a>
          <p></p>
          {/* <a className="button" href="/images/menu/takeoutflyer.pdf">Take Out Flyer</a>
          <p></p> */}
        </div>
      </section>
    );
}
// }
