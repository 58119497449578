import React from "react"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
import content from "../../content/products.yaml"

export default () => (
  <section id="styles"
    style= {{
      padding: '90px 0 72px',
      background: '#fff'
    }}
  >
    <div className="row add-bottom">

      <hr />
            { content.products.map((product, index) =>
            <div className="row add-bottom">
              <div className="price-block">
                <h3> { product.name }
                </h3>
                <p><a href="https://duckduckgo.com/"><img width="120" height="120" className="pull-left" alt={ product.name } src={ product.image } /></a>
                     { product.body }
                </p>
              </div>
            </div>
          )
          }
    </div>
  </section> 
)
